import React from "react";
import brc from "../../images/brc.png";
import global from "../../images/global-gap.png";
import haccp from "../../images/haccp.png";
import leaf from "../../images/leaf.png";
import nurture from "../../images/nurture.png";
import sedexsmeta from "../../images/sedex-smeta.png";
import foodexperts from "../../images/food-experts.png";
import grasp from "../../images/grasp.png";
import mands from "../../images/m-and-s.png";
import rainforest from "../../images/rainforest-alliance-certified.png";
import { Link, StaticQuery, graphql } from "gatsby";

export const Footer = ({ cssClass }) => {
	const RenderContactInfo = ({ data }) => (
		<>
			<div className="c-footer__section">
				<h2 className="c-footer__title">Contact us</h2>
				<ul className="c-footer__list">
					<li className="c-footer__item">
						<a href={`mailto:${data.datoCmsContact.email}`} className="c-btn c-btn--text">
							<div className="c-btn__icon">
								<svg fill="none" viewBox="0 0 16 16">
									<path d="M0 8h15M10 3l5 5-5 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<svg fill="none" viewBox="0 0 16 16">
									<path d="M0 8h15M10 3l5 5-5 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</div>
							<div className="c-btn__text">{data.datoCmsContact.email}</div>
						</a>
					</li>
					<li className="c-footer__item">
						<a href={`tel:${data.datoCmsContact.phoneNumber.replace(" ", "")}`} className="c-btn c-btn--text">
							<div className="c-btn__icon">
								<svg fill="none" viewBox="0 0 16 16">
									<path d="M0 8h15M10 3l5 5-5 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
								<svg fill="none" viewBox="0 0 16 16">
									<path d="M0 8h15M10 3l5 5-5 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</div>
							<div className="c-btn__text">{data.datoCmsContact.phoneNumber}</div>
						</a>
					</li>
				</ul>
			</div>
			<div className="c-footer__section">
				<h2 className="c-footer__title">Visit us</h2>
				<div className="c-footer__summary" dangerouslySetInnerHTML={{ __html: data.datoCmsContact.address }} />
			</div>
		</>
	);
	return (
		<footer className="c-footer">
			<div className="container">
				<div className="row">
					<div className="c-footer__container">
						<StaticQuery query={`${staticQuery}`} render={(data) => <RenderContactInfo data={data} />} />
						<div className="c-footer__section">
							<h2 className="c-footer__title">Policies</h2>
							<ul className="c-footer__list">
								<li className="c-footer__item">
									<Link to="/modern-slavery-policy" className="c-footer__link">
										Modern Slavery Policy
									</Link>
								</li>
							</ul>
						</div>
						<div className="c-footer__section">
							<h2 className="c-footer__title">Follow us</h2>
							<ul className="c-footer__social-list">
								<li className="c-footer__social-item">
									<a href="https://www.facebook.com/Pacific-Produce-162491577294204/" className="c-footer__social-link" target="_blank" rel="noreferrer">
										<div className="c-footer__social-icon c-footer__social-icon--facebook">
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.3933 15.9999V8.7015h2.4498l.3667-2.8442H9.3933v-1.816c0-.8235.2287-1.3847 1.4097-1.3847l1.5061-.0008V.112C12.0485.0774 11.1545 0 10.1144 0 7.9428 0 6.4561 1.3255 6.4561 3.7597v2.0976H4v2.8443h2.456V16l2.9373-.0001z"
													fill="#fff"
												/>
											</svg>
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.3933 15.9999V8.7015h2.4498l.3667-2.8442H9.3933v-1.816c0-.8235.2287-1.3847 1.4097-1.3847l1.5061-.0008V.112C12.0485.0774 11.1545 0 10.1144 0 7.9428 0 6.4561 1.3255 6.4561 3.7597v2.0976H4v2.8443h2.456V16l2.9373-.0001z"
													fill="#fff"
												/>
											</svg>
										</div>
										<span className="u-visually-hide">Visit us on Facebook</span>
									</a>
								</li>
								<li className="c-footer__social-item">
									<a href="https://twitter.com/PacificProduce" className="c-footer__social-link" target="_blank" rel="noreferrer">
										<div className="c-footer__social-icon c-footer__social-icon--twitter">
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16 2.5784a6.412 6.412 0 01-1.8848.5297c.678-.416 1.1983-1.0757 1.4434-1.8616-.6348.3853-1.3364.6655-2.0858.8157C12.8755 1.4083 12.0218 1 11.0771 1 9.2655 1 7.7957 2.5072 7.7957 4.3658c0 .264.0282.52.0846.7666-2.7282-.1406-5.1465-1.4792-6.7665-3.518-.2827.4989-.4442 1.0777-.4442 1.694 0 1.1672.5795 2.1977 1.4604 2.8015-.5372-.0164-1.0444-.1695-1.4876-.4199v.0414c0 1.6314 1.1316 2.9922 2.6342 3.3004-.2751.079-.5653.1184-.865.1184a3.189 3.189 0 01-.6179-.0597c.418 1.3367 1.6294 2.3104 3.0663 2.3364-1.1232.9033-2.5394 1.4416-4.0768 1.4416A6.5731 6.5731 0 010 12.8223c1.4528.9534 3.179 1.511 5.0319 1.511 6.0386 0 9.3396-5.1282 9.3396-9.5765 0-.1463-.0028-.2927-.0084-.4362A6.738 6.738 0 0016 2.5784z"
													fill="#fff"
												/>
											</svg>
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16 2.5784a6.412 6.412 0 01-1.8848.5297c.678-.416 1.1983-1.0757 1.4434-1.8616-.6348.3853-1.3364.6655-2.0858.8157C12.8755 1.4083 12.0218 1 11.0771 1 9.2655 1 7.7957 2.5072 7.7957 4.3658c0 .264.0282.52.0846.7666-2.7282-.1406-5.1465-1.4792-6.7665-3.518-.2827.4989-.4442 1.0777-.4442 1.694 0 1.1672.5795 2.1977 1.4604 2.8015-.5372-.0164-1.0444-.1695-1.4876-.4199v.0414c0 1.6314 1.1316 2.9922 2.6342 3.3004-.2751.079-.5653.1184-.865.1184a3.189 3.189 0 01-.6179-.0597c.418 1.3367 1.6294 2.3104 3.0663 2.3364-1.1232.9033-2.5394 1.4416-4.0768 1.4416A6.5731 6.5731 0 010 12.8223c1.4528.9534 3.179 1.511 5.0319 1.511 6.0386 0 9.3396-5.1282 9.3396-9.5765 0-.1463-.0028-.2927-.0084-.4362A6.738 6.738 0 0016 2.5784z"
													fill="#fff"
												/>
											</svg>
										</div>
										<span className="u-visually-hide">Visit us on Twitter</span>
									</a>
								</li>
								<li className="c-footer__social-item">
									<a href="https://www.linkedin.com/company/pacific-produce-ltd" className="c-footer__social-link" target="_blank" rel="noreferrer">
										<div className="c-footer__social-icon c-footer__social-icon--linkedin">
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16 15.5455h-3.5362v-5.1134c0-1.3384-.5532-2.252-1.7695-2.252-.9304 0-1.4478.6165-1.6887 1.2108-.0903.2133-.0762.5103-.0762.8075v5.3471H5.4262s.0451-9.0578 0-9.8811h3.5032V7.215c.207-.678 1.3265-1.6457 3.1129-1.6457C14.2587 5.5694 16 6.9907 16 10.0512v5.4943zM1.8834 4.4285h-.0226C.7318 4.4284 0 3.673 0 2.7155 0 1.7396.7535 1 1.905 1c1.1505 0 1.858.7377 1.8805 1.7128 0 .9575-.73 1.7156-1.9021 1.7156zM.4036 5.6643H3.522v9.8811H.4036V5.6644z"
													fill="#fff"
												/>
											</svg>
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16 15.5455h-3.5362v-5.1134c0-1.3384-.5532-2.252-1.7695-2.252-.9304 0-1.4478.6165-1.6887 1.2108-.0903.2133-.0762.5103-.0762.8075v5.3471H5.4262s.0451-9.0578 0-9.8811h3.5032V7.215c.207-.678 1.3265-1.6457 3.1129-1.6457C14.2587 5.5694 16 6.9907 16 10.0512v5.4943zM1.8834 4.4285h-.0226C.7318 4.4284 0 3.673 0 2.7155 0 1.7396.7535 1 1.905 1c1.1505 0 1.858.7377 1.8805 1.7128 0 .9575-.73 1.7156-1.9021 1.7156zM.4036 5.6643H3.522v9.8811H.4036V5.6644z"
													fill="#fff"
												/>
											</svg>
										</div>
										<span className="u-visually-hide">Visit us on LinkedIn</span>
									</a>
								</li>
								<li className="c-footer__social-item">
									<a href="https://www.instagram.com/goldcup2011/" className="c-footer__social-link" target="_blank" rel="noreferrer">
										<div className="c-footer__social-icon c-footer__social-icon--instagram">
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8 0C5.8273 0 5.555.0092 4.7016.0481c-.8515.0389-1.433.1741-1.9419.372-.526.2043-.9722.4779-1.417.9226-.4447.4448-.7183.891-.9227 1.417-.1978.5089-.333 1.0904-.3719 1.942C.0092 5.5548 0 5.8272 0 8s.0092 2.4451.0481 3.2984c.0389.8515.1741 1.433.372 1.9419.2043.5261.4779.9722.9226 1.417.4448.4447.891.7183 1.417.9227.5089.1978 1.0904.333 1.942.3719C5.5548 15.9908 5.8272 16 8 16s2.4451-.0092 3.2984-.0481c.8515-.0389 1.433-.1741 1.9419-.3719.5261-.2044.9722-.478 1.417-.9227.4447-.4448.7183-.8909.9227-1.417.1978-.5089.333-1.0904.3719-1.9419C15.9908 10.4451 16 10.1727 16 8s-.0092-2.4451-.0481-3.2984c-.0389-.8515-.1741-1.433-.3719-1.9419-.2044-.526-.478-.9722-.9227-1.417-.4448-.4447-.8909-.7183-1.417-.9227-.5089-.1978-1.0904-.333-1.9419-.3719C10.4451.0092 10.1727 0 8 0zm0 1.4414c2.1361 0 2.3891.0082 3.2327.0467.78.0355 1.2036.1659 1.4855.2754.3734.1452.6399.3185.9198.5985.28.28.4533.5464.5985.9198.1095.282.2398.7055.2754 1.4855.0385.8436.0467 1.0966.0467 3.2327s-.0082 2.3891-.0467 3.2327c-.0356.78-.1659 1.2036-.2754 1.4855-.1452.3734-.3185.6399-.5985.9198-.2799.28-.5464.4533-.9198.5985-.2819.1095-.7055.2398-1.4855.2754-.8435.0385-1.0965.0467-3.2327.0467-2.1363 0-2.3892-.0082-3.2327-.0467-.78-.0356-1.2036-.1659-1.4855-.2754-.3734-.1452-.6399-.3185-.9198-.5985-.28-.2799-.4533-.5464-.5985-.9198-.1095-.2819-.2399-.7055-.2754-1.4855-.0385-.8436-.0467-1.0966-.0467-3.2327 0-2.136.0082-2.3891.0467-3.2327.0355-.78.1659-1.2036.2754-1.4855.1452-.3734.3185-.6399.5985-.9198.28-.28.5464-.4533.9198-.5985.282-.1095.7055-.2399 1.4855-.2754C5.611 1.4496 5.864 1.4414 8 1.4414zM3.892 8C3.8919 5.7311 5.7311 3.892 8 3.892c2.2689 0 4.1081 1.8392 4.1081 4.1081 0 2.2689-1.8392 4.1081-4.108 4.1081-2.269 0-4.1082-1.8392-4.1082-4.108zM8 10.6667c-1.4728 0-2.6667-1.194-2.6667-2.6667 0-1.4728 1.194-2.6667 2.6667-2.6667 1.4728 0 2.6667 1.194 2.6667 2.6667 0 1.4728-1.194 2.6667-2.6667 2.6667zm4.2704-5.9771a.96.96 0 00.96-.96c0-.5302-.4298-.96-.96-.96s-.96.4298-.96.96a.96.96 0 00.96.96z"
													fill="#fff"
												/>
											</svg>
											<svg fill="none" viewBox="0 0 16 16">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8 0C5.8273 0 5.555.0092 4.7016.0481c-.8515.0389-1.433.1741-1.9419.372-.526.2043-.9722.4779-1.417.9226-.4447.4448-.7183.891-.9227 1.417-.1978.5089-.333 1.0904-.3719 1.942C.0092 5.5548 0 5.8272 0 8s.0092 2.4451.0481 3.2984c.0389.8515.1741 1.433.372 1.9419.2043.5261.4779.9722.9226 1.417.4448.4447.891.7183 1.417.9227.5089.1978 1.0904.333 1.942.3719C5.5548 15.9908 5.8272 16 8 16s2.4451-.0092 3.2984-.0481c.8515-.0389 1.433-.1741 1.9419-.3719.5261-.2044.9722-.478 1.417-.9227.4447-.4448.7183-.8909.9227-1.417.1978-.5089.333-1.0904.3719-1.9419C15.9908 10.4451 16 10.1727 16 8s-.0092-2.4451-.0481-3.2984c-.0389-.8515-.1741-1.433-.3719-1.9419-.2044-.526-.478-.9722-.9227-1.417-.4448-.4447-.8909-.7183-1.417-.9227-.5089-.1978-1.0904-.333-1.9419-.3719C10.4451.0092 10.1727 0 8 0zm0 1.4414c2.1361 0 2.3891.0082 3.2327.0467.78.0355 1.2036.1659 1.4855.2754.3734.1452.6399.3185.9198.5985.28.28.4533.5464.5985.9198.1095.282.2398.7055.2754 1.4855.0385.8436.0467 1.0966.0467 3.2327s-.0082 2.3891-.0467 3.2327c-.0356.78-.1659 1.2036-.2754 1.4855-.1452.3734-.3185.6399-.5985.9198-.2799.28-.5464.4533-.9198.5985-.2819.1095-.7055.2398-1.4855.2754-.8435.0385-1.0965.0467-3.2327.0467-2.1363 0-2.3892-.0082-3.2327-.0467-.78-.0356-1.2036-.1659-1.4855-.2754-.3734-.1452-.6399-.3185-.9198-.5985-.28-.2799-.4533-.5464-.5985-.9198-.1095-.2819-.2399-.7055-.2754-1.4855-.0385-.8436-.0467-1.0966-.0467-3.2327 0-2.136.0082-2.3891.0467-3.2327.0355-.78.1659-1.2036.2754-1.4855.1452-.3734.3185-.6399.5985-.9198.28-.28.5464-.4533.9198-.5985.282-.1095.7055-.2399 1.4855-.2754C5.611 1.4496 5.864 1.4414 8 1.4414zM3.892 8C3.8919 5.7311 5.7311 3.892 8 3.892c2.2689 0 4.1081 1.8392 4.1081 4.1081 0 2.2689-1.8392 4.1081-4.108 4.1081-2.269 0-4.1082-1.8392-4.1082-4.108zM8 10.6667c-1.4728 0-2.6667-1.194-2.6667-2.6667 0-1.4728 1.194-2.6667 2.6667-2.6667 1.4728 0 2.6667 1.194 2.6667 2.6667 0 1.4728-1.194 2.6667-2.6667 2.6667zm4.2704-5.9771a.96.96 0 00.96-.96c0-.5302-.4298-.96-.96-.96s-.96.4298-.96.96a.96.96 0 00.96.96z"
													fill="#fff"
												/>
											</svg>
										</div>
										<span className="u-visually-hide">Visit us on Instagram</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="c-footer__logos">
				<div className="container">
					<div className="row">
						<div className="c-footer__logos-container">
							<p className="c-footer__logos-text">All our farms have Global Gap accreditation as well as many other technical certifications requested by our global clients.</p>
							<ul className="c-footer__logos-list">
								<li className="c-footer__logos-item">
									<img src={brc} className="c-footer__logos-img" alt="BRC" />
								</li>
								<li className="c-footer__logos-item">
									<img src={global} className="c-footer__logos-img" alt="Global Gap" />
								</li>
								<li className="c-footer__logos-item">
									<img src={haccp} className="c-footer__logos-img" alt="HACCP Certified" />
								</li>
								<li className="c-footer__logos-item">
									<img src={leaf} className="c-footer__logos-img" alt="Leaf" />
								</li>
								<li className="c-footer__logos-item">
									<img src={nurture} className="c-footer__logos-img" alt="Nurture" />
								</li>
								<li className="c-footer__logos-item">
									<img src={sedexsmeta} className="c-footer__logos-img" alt="Nurture" />
								</li>
								<li className="c-footer__logos-item">
									<img src={foodexperts} className="c-footer__logos-img" alt="Nurture" />
								</li>
								<li className="c-footer__logos-item">
									<img src={grasp} className="c-footer__logos-img" alt="Nurture" />
								</li>
								<li className="c-footer__logos-item">
									<img src={mands} className="c-footer__logos-img" alt="Nurture" />
								</li>
								<li className="c-footer__logos-item">
									<img src={rainforest} className="c-footer__logos-img" alt="Nurture" />
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

const staticQuery = graphql`
	query {
		datoCmsContact {
			address
			email
			phoneNumber
		}
	}
`;
