import React from "react";
import "focus-visible";
import "../../styles/site.scss";
import { Navigation } from "./navigation";
import { Footer } from "./footer";

export const Layout = ({ children, location }) => {
	return (
		<>
			<Navigation />
			<main>{children}</main>
			<Footer />
		</>
	);
};

export default Layout;
